
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="true"
    append-to-body
    style="text-align:left;"
    width="1200px"
    :close-on-click-modal="false"
    @close="closeDialog"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" inline :rules="ruleValidate" :label-width="'150px'">
        <el-table
        :data="tableData"
        style="min-height:400px;"
        v-loading="loading"
        stripe
        >
        <el-table-column
            prop="merchantAccountName"
            label="商家账户"
            width="200"
        ></el-table-column>
        <el-table-column
            prop="realName"
            label="真实姓名"
            width="100"
        ></el-table-column>
                <el-table-column
            prop="phone"
            label="电话号码"
            width="120"
        ></el-table-column>
                <el-table-column
            prop="openId"
            label="openId"
            width="200"
        ></el-table-column>
                        <el-table-column
            prop="bankCard"
            label="银行卡号"
            width="200"
        ></el-table-column>
        <el-table-column
            prop="bank"
            label="所属银行"
            width="200"
        ></el-table-column>
       <el-table-column prop="status"  label="提现账户">
           <template slot-scope="{row}">
               <el-switch
                v-model="row.status"
                @change="handleChange(row)"
                >
                </el-switch>
           </template>
       </el-table-column>
        </el-table>
        <el-pagination
        style="text-align: center;"
        :current-page.sync="pageIndex"
        :total="totalElements"
        :page-sizes="pageSizeList"
        @current-change="changePage"
        @size-change="pageSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <!-- <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button> -->
    </span>
  </el-dialog>
</template>
<script>
import merchantUserApi from "@/api/base/merchantUser";

export default {
  props: ["businessKey", "title"],
  computed: {
      ruleValidate (){
        var rules = null;
        rules = {
            deviceNo: [
                { required: true, message: "桩号不能为空", trigger: "blur" }
            ],
            name: [
                { required: true, message: "桩名不能为空", trigger: "blur" }
            ],
            templateId:[
              {required: true, message: "收费模式不能为空", trigger: "blur" }
            ]
        };
        return rules;
      }
  },
  data() {
    return {
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {
        name:"",
        parentId:"",
      },
      tableData:[],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30]
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("merchantAccountId", self.businessKey);

      merchantUserApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    handleChange(row){
      var self = this;

      self.loading = true;
      
      var formData = new FormData();

      formData.append("id", row.id);
      formData.append("status", row.status);

      merchantUserApi.changeStatus(formData).then(resp => {
        self.loading = false;
        var jsonData = resp.data;
        if (jsonData.result) {
          this.$message.success("修改成功！");
        } else {
          this.$message.error(jsonData.message + "");
        }

        self.changePage(self.pageIndex);
      });
    }
  },
  created() {

  },
  mounted: function () {
    this.changePage(1);
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-panel {
  margin: 10px auto;
}
</style>